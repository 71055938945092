<template>
    <TestComponent @disable-enable="disableEnableGroup"
                   @create-submit="makeGroup"
                   @edit-submit="editName"
                   @delete-submit="deleteGroup"
                   @edit-show="setCurrentGroup"
                   @create-show="setCurrentGroup"
                   tableTitle="Group Management"
                   createModalTitle="Group Creator"
                   :data="groups"
                   :objType="{one: 'group', multi: 'groups'}"
                   nameField="groupname"
                   :orderBy="{column: 'groupname', ascending: true}"
                   :formFields="[{name: 'groupname', type:'text', required: true, label: 'Group Name'}]"
                   :columns="['groupname','constellationnames', 'edit']"
                   :headings="{groupname: 'Group Name',constellationnames: 'Constellations', edit: 'Edit'}"
                   :sortable="['groupname','constellationnames']"
                   :filterable="['groupname', 'constellationnames']"
                   :tabs="['Group', 'Custom Data']">

        <div slot="editModalInput">
            <label class="form-control-label">Constellations:</label>
            <v-client-table :columns="['constellationname', 'select']" :data="constellations" :options="table.options" :theme="table.theme" class="dataTable p-0 m-0 w-100">
                <template slot="h__select" style="float: right">
                    <b-form-checkbox @change="currentGroup.constellations = selectAllEdit()" color="success" style="float:right;"
                                     :indeterminate="intermediate" ref="allSelectedEdit"/>
                </template>
                <div slot="select" slot-scope="props" style="float: right">
                    <b-form-checkbox v-model="currentGroup.constellations" :value="props.row._id" color="success"
                                     style="float:right;"/>
                </div>
            </v-client-table>
        </div>

        <div slot="createModalInput">
            <label class="form-control-label">Constellations:</label>
            <v-client-table :columns="table.columns" :data="constellations" :options="table.options" :theme="table.theme" class="dataTable p-0 m-0 w-100">
                <template slot="h__select" style="float: right">
                    <b-form-checkbox @change="newConstellations = selectAllCreate()" color="success" style="float:right;"
                                     :indeterminate="intermediate" ref="allSelectedCreate"/>
                </template>
                <div slot="select" slot-scope="props" style="float: right">
                    <b-form-checkbox v-model="newConstellations" :value="props.row._id" color="success"
                                     style="float:right;"/>
                </div>
            </v-client-table>
        </div>

        <!--Create Modal Custom Tab-->
        <div slot="createModalTabInput1">

            <label class="form-control-label">Custom Claims</label>
            <ClaimsTable :editClaims="[]" :editBool="false" />

            <br>

            <label class="form-control-label">Custom Fields</label>
            <b-textarea placeholder="Custom Fields" v-model="newCustomFields"></b-textarea>
        </div>

        <!--Edit Modal Custom Tab-->
        <div slot="editModalTabInput1">
            <label class="form-control-label">Custom Claims</label>
            <ClaimsTable :editClaims="editClaims" :editBool="true" />

            <br>

            <label class="form-control-label">Custom Fields</label>
            <b-textarea placeholder="Custom Fields" v-model="customFields"></b-textarea>
        </div>

    </TestComponent>
</template>

<script>
import TestComponent from '../Component.vue';
import ClaimsTable from "@/components/ClaimsTable";
import iss from "@/services/iss";
import claimsStore from "@/store/claimsStore";
import constellationStore from "@/store/constellationStore";
import {ClientTable} from 'vue-tables-2';
import Vue from "vue";

Vue.use(ClientTable);

export default {
    components: {
        TestComponent,
        ClaimsTable
    },
    updated() {
    },
    data: () => {
        return {
            allSelected: false,
            intermediate: false,
            claims: [],
            constellations: [],
            currentGroup: {
                constellations: [],
            },
            customFields: '',
            editClaims: [],
            groups: [],
            newConstellations: [],
            newCustomFields: '',
            table: {
                columns: ["constellationname", "select"],
                options: {
                    orderBy: {column: 'constellationname', ascending: true},
                    headings: {
                        constellationname: 'Constellation Name',
                    },
                    sortable: ['constellationname'],
                    filterable: ['constellationname'],
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    initialPage: 1,
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 10,
                    skin: 'table table-striped table-hover',
                },
                useVuex: false,
                theme: 'bootstrap4',
                template: 'default',
            }
        }
    },
    created() {
        this.groups = [];
        this.getGroups();
        this.getClaims();
    },
    watch: {
        currentGroup: {
            deep: true,
            handler() {
                this.currentGroupHelper()
            },
        },
        newConstellations: {
            deep: true,
            async handler() {
                if (Array.isArray(this.newConstellations)) {
                    let all = this.constellations.filter(_ => {
                        return this.newConstellations.includes(_._id)
                    })
                    if (all.length === this.constellations.length) {
                        this.$refs.allSelectedCreate.indeterminate = false;
                        this.$refs.allSelectedCreate.checked = true;
                        this.intermediate = false;
                        this.allSelected = true;
                    } else if (all.length === 0) {
                        this.$refs.allSelectedCreate.indeterminate = false;
                        this.$refs.allSelectedCreate.checked = false;
                        this.intermediate = false;
                        this.allSelected = false;
                    } else {
                        this.$refs.allSelectedCreate.indeterminate = true;
                        this.$refs.allSelectedCreate.checked = false;
                        this.intermediate = true;
                        this.allSelected = false;
                    }
                }
            },
        },
    },
    methods: {
        currentGroupHelper() {
            if (this.$refs.allSelectedEdit === undefined) {
                setTimeout(this.currentGroupHelper, 100)
            } else {
                if (Array.isArray(this.currentGroup.constellations)) {
                    let all = this.constellations.filter(_ => {
                        return this.currentGroup.constellations.includes(_._id)
                    })
                    if (all.length === this.constellations.length) {
                        this.$refs.allSelectedEdit.indeterminate = false;
                        this.$refs.allSelectedEdit.checked = true;
                        this.intermediate = false;
                        this.allSelected = true;
                    } else if (all.length === 0) {
                        this.$refs.allSelectedEdit.indeterminate = false;
                        this.$refs.allSelectedEdit.checked = false;
                        this.intermediate = false;
                        this.allSelected = false;
                    } else {
                        this.$refs.allSelectedEdit.indeterminate = true;
                        this.$refs.allSelectedEdit.checked = false;
                        this.intermediate = true;
                        this.allSelected = false;
                    }
                }
            }
        },
        selectAllEdit() {
            let result = [];
            let all = this.constellations.filter(_ => {
                return this.currentGroup.constellations.includes(_._id)
            })
            if (all.length === this.constellations.length) {
                return result;
            } else {
                this.constellations.forEach(function (constellation, index) {
                    Vue.set(result, index, constellation._id);
                })
                return result;
            }
        },
        selectAllCreate() {
            let result = [];
            let all = this.constellations.filter(_ => {
                return this.newConstellations.includes(_._id)
            })
            if (all.length === this.constellations.length) {
                return result;
            } else {
                this.constellations.forEach(function (constellation, index) {
                    Vue.set(result, index, constellation._id);
                })
                return result;
            }
        },
        getClaims() {
            iss.getUserClaims().then(response => {
                this.claims = response.data;
            });
        },
        getGroups() {
            iss.getAllGroups().then(response => {
                constellationStore.dispatch('getConstellations').then(response1 => {
                    this.constellations = response1;
                    this.constellations.sort(this.compareConstellations);
                    response.data.forEach(function (group) {
                        group.constellationnames = [];
                        group.constellations.forEach(function (constellationId) {
                            this.constellations.forEach(function (constellation) {
                                if (constellationId === constellation._id) {
                                    group.constellationnames.sort();
                                    group.constellationnames.push(constellation.constellationname);
                                }
                            }.bind(this))
                        }.bind(this))
                        //once we've added all constellation names, push group
                        this.groups.push(group);
                    }.bind(this))
                }).catch(error => {
                    this.$mToast({
                        title: error.response + ' Error',
                        text: "Constellation couldn't be retrieved",
                        style: 'error'
                    });
                });
            }).catch(error => {
                this.$mToast({
                    title: 'Error',
                    text: "Groups couldn't be retrieved",
                    style: 'error'
                });
            });
        },
        setCurrentGroup(value) {
            this.editClaims = value.customClaims;
            this.currentGroup = value;
        },
        compareConstellations(a, b) {
            if (a.constellationname < b.constellationname) {
                return -1;
            } else {
                return 1;
            }
        },
        editName(currentGroup) {
            currentGroup.customClaims = claimsStore.getters.getSelectedClaims();
            currentGroup.customFields = {};
            try {
                currentGroup.customFields = JSON.parse(this.customFields);
            } catch (e) {
                this.$mToast({
                    title: 'Error',
                    text: 'Custom fields could not be parsed',
                    style: 'error'
                });
            }
            iss.updateGroups(currentGroup, this.currentGroup.constellations).then(() => {
                iss.getAllGroups().then(response => {
                    this.$mToast({
                        title: 'Success',
                        text: "Group updated",
                        style: 'success'
                    });
                    this.groups = response.data;
                    this.groups.forEach(function (group) {
                        group.constellationnames = [];
                        group.constellations.forEach(function (constellationId) {
                            this.constellations.forEach(function (constellation) {
                                if (constellationId === constellation._id) {
                                    group.constellationnames.push(constellation.constellationname)
                                }
                            }.bind(this))
                        }.bind(this))
                    }.bind(this))
                }).catch(error => {
                    this.$mToast({
                        title: error.response.status + ' Error',
                        text: "Groups couldn't be retrieved: " + error.response.statusText,
                        style: 'error'
                    });
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Groups couldn't be updated: " + error.response.statusText,
                    style: 'error'
                });
            });
        },
        deleteGroup(id) {
            iss.deleteGroups(id).then(() => {
                iss.getAllGroups().then(response => {
                    this.$mToast({
                        title: 'Success',
                        text: "Group deleted",
                        style: 'success'
                    });
                    this.groups = response.data;
                    this.groups.forEach(function (group) {
                        group.constellationnames = [];
                        group.constellations.forEach(function (constellationId) {
                            this.constellations.forEach(function (constellation) {
                                if (constellationId === constellation._id) {
                                    group.constellationnames.push(constellation.constellationname)
                                }
                            }.bind(this))
                        }.bind(this))
                    }.bind(this))
                }).catch(error => {
                    this.$mToast({
                        title: error.response.status + ' Error',
                        text: "Groups couldn't be retrieved: " + error.response.statusText,
                        style: 'error'
                    });
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Group couldn't be deleted: " + error.response.statusText,
                    style: 'error'
                });
            });
        },
        disableEnableGroup(currentGroup) {
            iss.updateActiveGroup(currentGroup.active, currentGroup._id).then(() => {
                iss.getAllGroups().then(response => {
                    this.$mToast({
                        title: 'Success',
                        text: "Group disabled/enabled",
                        style: 'success'
                    });
                    this.groups = response.data;
                    this.groups.forEach(function (group) {
                        group.constellationnames = [];
                        group.constellations.forEach(function (constellationId) {
                            this.constellations.forEach(function (constellation) {
                                if (constellationId === constellation._id) {
                                    group.constellationnames.push(constellation.constellationname)
                                }
                            }.bind(this))
                        }.bind(this))
                    }.bind(this))
                })
            });
        },
        makeGroup(group) {
            group.customClaims = claimsStore.getters.getSelectedClaims();
            group.customFields = {};
            group.constellations = this.constellations;
            try {
                group.customFields = JSON.parse(this.newCustomFields);
            } catch (e) {
                this.$mToast({
                    title: 'Error',
                    text: 'Custom fields could not be parsed',
                    style: 'error'
                });
            }
            iss.createGroups(group, this.newConstellations).then(() => {
                this.getGroups();
                location.reload();
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Group couldn't be created: " + error.response.statusText,
                    style: 'error'
                });
            });
        },
    }
}
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
    cursor: pointer;
}

.table td {
    border: none;
}
</style>